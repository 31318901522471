export const environment = {
    production: false,
    serverUrl: 'https://hkzwu32pm2.execute-api.us-east-2.amazonaws.com/dev',
    bucket: 'redthreadagaudit-dev-upload',
    userPool: {
      UserPoolId: 'us-east-2_SFW6F9ier',
      ClientId: '16c21iluhe7gqmp4ftm2ptrcct',
      region: 'us-east-2'
    },
    identityPoolId: 'us-east-2:6ac5e0b9-e217-4e1c-9a42-10ee928230f9',
    buckets: {
      'us-east-2': 'redthreadagaudit-dev-upload'
    },
    modules: {
      key: 'analysisType',
      values: [
        {
            'name': 'SMR',
            'desc': 'Small Molecule Report'
        },
             {
            'name': 'LMR',
            'desc': 'Large Molecule Report'
        },
        
      ]
  },
    defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId: 'us-east-2:6ac5e0b9-e217-4e1c-9a42-10ee928230f9', // REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_p5hbm0xBK', // OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '16c21iluhe7gqmp4ftm2ptrcct', // OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        bucket: 'redthreadagaudit-dev-upload', // REQUIRED -  Amazon S3 bucket
        region: 'us-east-2', // OPTIONAL -  Amazon service region
      }
    }
  };
  const awsconfig = {
    'aws_project_region': 'us-east-2',
    'aws_cognito_region': 'us-east-2',
    'aws_user_pools_id': 'us-east-2_SFW6F9ier',
    'aws_user_pools_web_client_id': '16c21iluhe7gqmp4ftm2ptrcct',
    'aws_cognito_mfa_configuration': 'OPTIONAL',
  
  };
  export default awsconfig;
  
  
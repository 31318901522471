import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import moment from 'moment';
import jsPDF from 'jspdf';
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { DropDownService } from '../../../service/drop-down.service';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { style } from '@angular/animations';


@Component({
  selector: 'app-pdf-layout',
  templateUrl: './pdf-layout.component.html',
  styleUrls: ['./pdf-layout.component.scss']
})


export class PdfLayoutComponent implements OnInit {
  maxDate;
  @Input() pdfData = [];
  @Input() flag: string;
  @ViewChild('htmlData', { static: false }) htmlData!: ElementRef;
  @ViewChild('missingTablesData', { static: false }) missingTablesData!: ElementRef;
  @Input() analysisDetailsData;
  @Input() missingTables;
  @Input() displayObservation;
  fileName: string;
  analysisType: string;
  analysisSubtype: string;
  outputTableTitle: string;
  logo: string;


  constructor(private dropDownService: DropDownService,
    private http: HttpClient,
    public datepipe: DatePipe) { }

  ngOnInit(): void {

    this.replaceStr();
    this.loadImageAsBase64('/assets/img/App-Biodata.png');
    // this.maxDate = moment(currentDate).format('DD/MM/YYYY/HHMM');
    this.maxDate = this.datepipe.transform((new Date), 'MM/dd/yyyy/h:mm:ss');
    this.fileName = this.analysisDetailsData.analytes[0].uploadObjs[0].files[0].name;
    this.analysisType = this.analysisDetailsData.analysisType;
    this.analysisSubtype = this.analysisDetailsData.analysisSubtype;

    if (this.analysisType == 'LMR') {
      this.analysisType = 'Large Molecule';
      if (this.analysisSubtype == 'SA0') {
        this.analysisSubtype = 'Sample Analysis'
      }
      else {
        this.analysisSubtype = 'Method Validation'
      }
    }

    else {
      this.analysisType = 'Small Molecule';
      if (this.analysisSubtype == 'SA0') {
        this.analysisSubtype = 'Sample Analysis'
      }
      else {
        this.analysisSubtype = 'Method Validation'
      }

    }


  }

  loadImageAsBase64(url: string) {
    this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
      const reader = new FileReader();
      reader.onload = () => {
        this.logo = reader.result as string;
      };
      reader.readAsDataURL(blob);
    });
  }

  async exportPDF() {
    const doc = new jsPDF();
    const pdfTable = this.htmlData.nativeElement;
    let html = htmlToPdfmake(pdfTable.innerHTML);
    const pdfmissingTable = this.missingTablesData.nativeElement;
    let html2 = htmlToPdfmake(pdfmissingTable.innerHTML)

    const documentDefinition = {
      content: [
        {
          image: this.logo,
          width: 150,
          style: 'logo',
        },
        {
          text: this.maxDate,
          width: 45,

        },
        {
          text: (this.analysisType + ' ' + this.analysisSubtype + ' ' + 'Findings'),
          width: 100,
          alignment: 'center',
          margin: [0, 5, 10, 0],
        },
        {
          text: this.fileName,
          width: 45,
          margin: [0, 5, 10, 0],
          alignment: 'center',
        },
        {
          stack: [
            html, // Insert converted HTML content here
          ],
          style: 'pdfContent',
        },
        ...this.getTablesContent(),
        {
          stack: [
            html2, // Insert converted HTML content here
          ],
          style: 'pdfContent'
        },
       
      ],
      styles: {
        logo: {
          alignment: 'right',
        },
        pdfContent: {
          fontSize: 8,
          margin: [0, 5, 0, 5],
        },
        errorbox :{
          border: ` 10px solid red; padding: 10px;`,
          color: 'black',
        },
        redTable: {
          fillColor: '#fbeae9'
        },
        tableHeader: {
          bold: true,
          fontSize: 9,
          color: 'black'
        },
        tableData: {
          fontSize: 7,
          color: 'black',
          borderStyle: 'solid',
          borderColor: 'red'
        },
        subHeading: {
          fontSize: 8,
          margin: [0, 10, 0, 5]
        },
        heading: {
          fontSize: 10,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        validation: {
          margin: [0, 5, 0, 5],
          fontSize: 8
        },
        observation: {
          margin: [0, 5, 0, 5],
          fontSize: 7
        }
      }


    };
    try {
      pdfMake.createPdf(documentDefinition).download(`RedThreadOutput${this.maxDate}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }

  getTablesContent() {
    const content = [];
    this.pdfData.forEach(table => {

      content.push({ text: table.table_title, style: 'heading', fillColor: this.getConditionalColor(table.table_failed) });
      content.push({ text: this.getAnalysisTypeText(table.table_type), style: 'subHeading' });

      table.validation?.forEach(val => {
        content.push({ text: `${val.val_type}: ${val.val_result}`, style: 'validation' });
      });

      content.push({ text: 'Analysis Result', style: 'subHeading' });

      if (table.result?.item[2].message.length > 0) {
        content.push(this.createTable('Green', table.result.item[2].message, table.table_failed));
      }

      if (table.result?.item[0].message.length > 0) {
        content.push(this.createTable('Red', table.result.item[0].message, table.table_failed));
      }

      if (table.result?.item[1].message.length > 0) {
        content.push(this.createTable('Yellow', table.result.item[1].message, table.table_failed));
      }

      if (this.displayObservation && table.additional_observation?.length > 0) {
        content.push(this.createTable('Additional Observations', table.additional_observation, table.table_failed)

        );
      }
    });
    
   return content;
  }



  createTable(title, messages, tableFailed) {
    const borderColor = this.getConditionalColor(tableFailed);
    return {
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [
          [{ text: title, style: 'tableHeader' }],
          ...messages.map(msg => [{ text: msg, style: 'tableData' }])
        ]
      },
      layout: {
        hLineColor: () => borderColor,
        vLineColor: () => borderColor,
        hLineWidth: () => 0.5,
        vLineWidth: () => 0.5,

      }
    };
  }


  private getConditionalColor(value: any) {
    // Apply conditional coloring logic here
    if (value && this.flag === 'Critical') {
      return 'red';
    }
    return null;
  }

  getAnalysisTypeText(type) {
    return `Processed as  ${this.startsWithVowel(type.toLowerCase()) ? 'an' : 'a'} ${this.shouldAddTableWord(type) ? type + ' Table' : type}`;
  }


  replaceStr() {
    const updatedArrayOfArrays = this.pdfData.map(obj => {
      return { ...obj, table_title: obj.table_title.replace(/℃/g, "°C") };
    });
    this.pdfData = updatedArrayOfArrays;
  }

  findKey(table) {
    return table.hasOwnProperty('error')
  }

  startsWithVowel(word: string): boolean {
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    return vowels.includes(word[0]);
  }

  shouldAddTableWord(table: string): boolean {
    return !table.toLowerCase().includes('table');
  }


  getDisplayString(key) {
    return this.dropDownService.nameDescMap.get(key);
  }


}

export enum FileObjectStatus {
    NotStarted,
    Uploading,
    Uploaded,
    Canceled,
    Deleted,
    Failed
}

export class  FileObject {
    status = FileObjectStatus.NotStarted;
    type: string;
    name: any;
    constructor(public file: File, type: string) {
        this.type = type;
        this.name = this.file.name;
    }
}

export class Uploads {
    uploadType: string;
    index: number;
    path: string;
    files: any;
}
export class Analyte {
    analyteName: string;
    uploadObjs: Uploads[];
    tempPath: string;
    constructor(name) {
        this.analyteName = name;
        this.uploadObjs = [];
    }
}
export class AnalysisDetails {
    userId: string;
    analysisId: string;
    analysisStatus?: string;
    analysisType?: string;
    analysisSubtype?: string;
    analysisSubtype_ADA?: string;
    report_template?: string;
    projectCode?: string;
    regressionModel?: string;
    lts80_dialog?: string;
    lts20_dialog?: string;
    lloq?: string;
    uloq?: string;
    extraction_dialog?: string;
    benchtop_dialog?: string;
    freeze_thaw_dialog?: string;
    first_patient_dialog?: string;
    sampleMatrix?: string;
    antiCoagulant?: string;
    species?: string;
    samplePrep?: string;
    analyteNames: string[];
    sampleDate: string;
    analytes?: Analyte[];
    weighting_factor?: string;
    STD_conc_arr: string[];
    anchor_point_1?: string;
    anchor_point_2?: string;
    Dilution_QC_conc?: string;
    Low_QC_conc?: string;
    Mid_QC_conc?: string;
    High_QC_conc?: string;
    sample_volume?: string;
    report_id: string;
    method_id: string;
    concomitant_name: string;
    comed_conc: string;
    benchTop_Temp: string;
    noOfcycles: string;
    benchtop_hours: string;
    frozen_temp: string;
    longTerm_frozen_temp: string;
    longTerm_storage_days: string;
    protocol_id: string;
    thawing_temp: string;
    normalityTestData: string;
    screening_cutpoint: string;
    confirmatory_cutpoint: string;
    concentrationUnit: string;
    tukeyCoefficient: string[];
    negativeControlValues: string;
    nabDataset: string;
    multipleAnalytes: string;
    method_type: string;
    ada_subtype: string;
    ada_subtype_cutpoint: string;
    regressionType: string;
    PL: string;
    CVThreshold: string;
    selectedValidationTableIds: string[];
    selectedAssayTableIds: string[];
    selectedGeneralTableIds: string[];
    re_type: string;
    re_value: string;
    tableParamValue: string[];
    files: string[];
    file_path: string;
    analyteName: string[];
    selectedGeneralTableName: string[];
    selectedAssayTableName: string[];
    selectedValidationTableName: string[];
    thresholdLayout: string;
    Intra_Precision_CV:number;
    Inter_Precision_CV:number;
    Slope_cal_curve_reg:number;
    executionId:string;
    selectedValues: { [tableId: string]: { [fieldName: string]: string } } = {};
    group:string;
    organization:string;
   
    constructor(userId) {
        this.userId = userId;
        this.analysisId = Math.floor(new Date().getTime() / 1000.0).toString();
        this.analysisType = '';
        this.analytes = [];
    }
}

export interface UploadedFiles {
    type: string,
    name: string
}

export interface DropDowns {
    key: string;
    values: DropDown[]
}

export interface DropDown {
    name: string;
    desc: string;
}


export interface SearchResult {
    userId: string;
    analysisId: string;
    analysisDate?: string;
    analysisType: string;
    analysisSubtype?: string;
    analysisTypeDisplay?: string;
    analysisResult?: string;
    analysisResultFile?: string;
    analysisResultData?: string;
    analysisStatus?: string;
    analysisDetails?: string;
    selectedTables?: string;
    analyteNames?: string;
    projectNames?: string;
    tableData?: string;
    tableReselection?: number;
    AnalysisResultGraph?: string;
    Messages?: string[];
    Tables?: NewTable[];
    MissingTables:string[];
    
}


export interface columnsResult{
    message:string;
    data:columnsData[];
}
export interface columnsData{
    table_id:string;
    columns:string[];
}
export class NewTable {

    table_type?: string;
    table_title?: string;
    analysis_type?: string;
    table_data?: ResponseTable;
    missing_columns?: string;
    threshold?: Dictonary[];
    result?: Result;
    identified_columns:any;
    table_id:string[]; 
    table_failed?: boolean;  
}

export class Dictonary {
    key: string;
    value: string;
}

export class Result {
    item?: Item[];
    green: string;
    yellow: string;
    red: string;
}

export class Item {
    color: string;
    message: string[];
}
export class ResponseTable {
    columns: string[];
    data: string[];

}

export class UserData {
    data: SearchResult[]
}

export interface AnalysisData {
    data: SearchResult
}

export  class PreviousAnalysisData {
    data: PreviousAnalysis[]
}

export class PreviousAnalysis {
    userId: string;
    analysisId: string;
    analysisKey: string;
}

export class AnalysisKey {
    userId: string;
    analysisId: string;
    analysisDate?: string;
    analysisType: string;
    analysisSubtype?: string;
    analyteNames?: string[];
    projectCode?: string;
    // projectCodeMV?: string;
    projectNames?: string;
    analysisTypeDisplay?: string;
    analysisSubtypeDisplay?: string;

}

export class RDSAnalysisData {
    user_id: string;
    organization_id: string;
    group_id: string;
    analysis_id: string;
    file_name: string;
    analysis_type: string;
    analysis_date?: string;
    project_code: string;
    analysis_status?: string;
}

export class AWSResponse {
    processcode: string;
    message: string;
    analysisID: string;
}



export class Dashboard {
    userId: string;
    analysisDate: Date;
    analyteNames: string[];
    analysisType: string;
    analysisSubtype?: string;
    projectCode?: string;
    // projectCodeMV?: string;
}

export class Summary {
    count: [];
    date: [];
    sumamry_array: AnalysisSummary[]

}

export class AnalysisSummary {
    userId: string;
    analysisId: string;
    overall_g: number;
    overall_y: number;
    overall_r: number;
    date: string;
    analytes: string[];
    projectCode: string;
    // projectCodeMV: string;
    analysisType: string;
    analysisSubtype: string;

}

export  class Logs {
    data: AuthLog[];
    users: string[];
    file: string;
}
export class AuthLog {
    userId: string;
    activityDate: string;
    activityType: string;
}

export interface Table {
    analyte_name: string;
    table_type: string;
    table_index: string;
    table_title: string;
    analysis_type: string;
    rows: string;
    valid: boolean
}
export class Templates {
    type: string;
    description: string;
    columns: TemplateDetail[];
}
export class TemplateDetail {
    name: string;
    desc: string;
    type: string;
    keys: string[];
    required: boolean;
}


export class AnalysisDetailsADA {
    analysisType?: string;
    analysisSubtype?: string;
    projectCode?: string;
    regressionModel?: string;
    sampleMatrix?: string;
    antiCoagulant?: string;
    species?: string;
    samplePrep?: string;
    analyteNames: string[];
    analytes?: Analyte[];
    normalityTestData: string;
    screening_cutpoint: string;
    confirmatory_cutpoint: string;
    concentrationUnit: string;
    tukeyCoefficient: string[];
    nabDataset: string;
    multipleAnalytes: string;
    method_type: string;
    ada_subtype: string;
    ada_subtype_cutpoint: string;
    regressionType: string;
    PL: string;
    CVThreshold: string;
}

export class AnalysisDetailsSLR {
    analysisType?: string;
    analysisSubtype?: string;
    uploadedDocumentFormat?: string;
    projectCode?: string;
    // projectCodeMV?: string;
    regressionModel?: string;
    lloq?: string;
    uloq?: string;
    re_type?: string;
    re_value?: string;
    lts80_dialog?: string;
    extraction_dialog?: string;
    benchtop_dialog?: string;
    freeze_thaw_dialog?: string;
    multipleAnalytes?: string;
    first_patient_dialog?: string;
    sampleMatrix?: string;
    antiCoagulant?: string;
    species?: string;
    samplePrep?: string;
    sampleDate?: string;
    analyteNames?: string[];
    // tableparamName?: string[];
    // selectedValidationTableIds?: string[];
    // selectedAssayTableIds?: string[];
    // selectedGeneralTableIds?: string[];
    tableParamValue?: string[];
    files: [];
    file_path: string[];
    analyteName: string[];
    // selectedGeneralTableName?: string[];
    // selectedAssayTableName?: string[];
    // selectedValidationTableName?: string[];
    thresholdLayout: string;
    // group:string;
    // organization:string;

}

export class AnalysisDetailsqPCR {
    analysisType?: string;
    projectCode?: string;
    multipleAnalytes?: string;
    analyteNames?: string[];
    files: [];
    file_path: string[];
    analyteName: string[];
    Intra_Precision_CV:number;
    Inter_Precision_CV:number;
    Slope_cal_curve_reg:number;

}

export class DashboardData{
    EmbedUrl:string;
}
 

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  float: right;
  margin: 10px;
}

.img-fluid {
  float: right;
  margin-left: 20px;
}

#date {
  float: left;
  font-style: italic;
  margin: 20px;
}

.heading {
  text-decoration: underline;
}

.file {
  margin: 20px;
  text-align: center;
}

.fileheading {
  margin-top: 30px;
  text-align: center;
}

hr {
  margin: 0px;
}

.errorbox {
  border-style: solid;
  border-color: rgb(224, 52, 52);
  color: #0a0a0a;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/small-molecule/pdf-layout/pdf-layout.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF;;AAQA;EACE,WAAA;EACA,kBAAA;EACA,YAAA;AALF;;AAQA;EACE,0BAAA;AALF;;AASA;EACE,YAAA;EACA,kBAAA;AANF;;AASA;EACE,gBAAA;EACA,kBAAA;AANF;;AAeA;EACE,WAAA;AAZF;;AAeC;EACC,mBAAA;EACA,8BAAA;EACA,cAAA;AAZF","sourcesContent":[".btn {\r\n  float: right;\r\n  margin: 10px;\r\n}\r\n\r\n.img-fluid {\r\n  float: right;\r\n  margin-left: 20px;\r\n}\r\n\r\n// table thead th { \r\n//     color: white;\r\n//      font-size:10pt;\r\n//       background-color: #3b5998\r\n//     }\r\n\r\n#date {\r\n  float: left;\r\n  font-style: italic;\r\n  margin: 20px;\r\n}\r\n\r\n.heading {\r\n  text-decoration: underline;\r\n}\r\n\r\n\r\n.file {\r\n  margin: 20px;\r\n  text-align: center;\r\n}\r\n\r\n.fileheading {\r\n  margin-top: 30px;\r\n  text-align: center;\r\n\r\n}\r\n\r\n// .row {\r\n//   float: left;\r\n//   margin: 20px;\r\n//   border: 2px;\r\n// }\r\nhr {\r\n  margin: 0px;\r\n}\r\n\r\n .errorbox {\r\n  border-style: solid;\r\n  border-color: rgb(224, 52, 52);\r\n  color: #0a0a0a;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
